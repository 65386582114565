section {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ffffff;
  width: 100%;

  header {
    height: 98px;
    border-bottom: #efefef solid 1px;
    border-radius: 8px 8px 0 0;
    background-color: #ffffff;
    display: flex;
    align-items: center;

    .dashboard-section-title {
      margin-left: 21px;
      color: #181818;
      font-size: 22px;
      font-weight: 700;
      line-height: 30px;
    }

    .dashboard-section-toolbar {
      margin-left: auto;
    }
  }

  .dashboard-section-body {
    padding: 0;
    display: flex;
    flex-direction: column;

    table {
      thead {
        height: 38px;

        th {
          color: #181818;
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
        }
      }

      tbody {
        tr {
          height: 42px;

          &:nth-of-type(odd) {
            background-color: #eef5fe;
          }

          td {
            padding-top: 0;
            padding-bottom: 0;
            color: #181818;
            font-size: 17px;
            font-weight: 400;
            vertical-align: middle;

            a {
              color: #54b8f3;
            }

            button {
              margin: 0;
              padding: 0;
              font-size: 17px;
              font-weight: 400;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}
