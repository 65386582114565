.nav {
  &.nav-side {
    height: 100%;
    background-color: #54b8f3;

    .nav-item {
      height: 67px;
      padding: 21px 32px;
      position: relative;

      &.nav-item-separator {
        height: 1px;
        padding: 0;
        background-color: #ffffff;
        opacity: 0.3;
      }

      &.active {
        background-color: #39a6e5;

        .active-item-indicator {
          display: block;
        }
      }

      a {
        padding-left: 21px;
        color: #ffffff;
        font-size: 17px;
        font-weight: 400;
        line-height: 30px;

        &:hover {
          text-decoration: none;
        }
      }

      .active-item-indicator {
        display: none;
        position: absolute;
        top: calc(50% - 8px);
        right: 23px;
      }
    }
  }
}
