section.invoices-panel {
  margin: 39px 0 44px;

  header {
    .dashboard-section-toolbar {
      .invoice-search-field {
        display: inline-flex;
        align-items: center;
        width: 456px;

        img {
          width: 17px;
          height: 18px;
        }

        span {
          color: #181818;
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
          margin: 0 15px 0 8px;
        }

        input {
          width: 219px;
          height: 40px;
          box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06), inset 0 2px 2px rgba(0, 0, 0, 0.22);
          border-radius: 8px;
          background-color: #ffffff;
          color: #181818;
          font-size: 17px;
          font-weight: 400;
          line-height: 30px;
        }

        button {
          width: 112px;
          height: 40px;
          border-radius: 8px;
          background-color: #54b8f3;
          color: #ffffff;
          font-size: 17px;
          font-weight: 400;
          margin: 0 0 0 15px;
        }
      }
    }
  }

  .dashboard-section-body {
    align-items: center;

    table {
      td {
        .pdfInvoiceIcon {
          margin-left: 9px;
        }
      }
    }

    .paginator {
      margin-bottom: 29px;
    }
  }
}
