.page-generate-password-content {
  header {
    font-size: 34px;
    font-weight: 500;
    line-height: 30px;
    color: #181818;
    margin: 94px 0 36px;
  }

  form {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
    padding: 30px 34px;
    width: 90%;

    .label-explanation {
      color: #181818;
      font-size: 17px;
      font-weight: 400;

      div {
        font-size: 15px;
        margin: 12px 0 30px;
      }
    }

    input[type="text"] {
      height: 47px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.11), inset 0 1px 1px rgba(0, 0, 0, 0.55);
      border-radius: 8px;
      background-color: #ffffff;
      opacity: 0.38;
      color: #181818;
      font-size: 17px;
      font-weight: 400;
    }

    .actions-panel {
      margin-top: 30px;
      flex-direction: column;
      align-items: center;

      button[type="submit"] {
        border-radius: 8px;
        font-size: 18px;
        font-weight: 400;
        background-color: #54b8f3;
        margin-left: 10px;
      }

      a {
        margin-top: 19px;
        color: #54b8f3;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
      }
    }
  }
}
