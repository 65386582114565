.page-login-content {
  header {
    font-size: 34px;
    font-weight: 500;
    line-height: 30px;
    color: #181818;
    margin: 94px 0 36px;
  }

  form {
    background-color: #ffffff;

    @include media-breakpoint-up(md) {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 30px 34px;
      width: 485px;
    }

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 30px 34px;
    width: 90%;
    label {
      color: #181818;
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
    }

    input[type="text"], input[type="password"] {
      width: 401px;
      height: 47px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.11), inset 0 1px 1px rgba(0, 0, 0, 0.55);
      border-radius: 8px;
      background-color: #ffffff;
      opacity: 0.38;
      color: #181818;
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
    }

    button[type="submit"] {
      width: 149px;
      border-radius: 8px;
      background-color: #54b8f3;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
    }

    .link-generate-password {
      margin-top: 19px;
      color: #54b8f3;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
    }
  }
}
