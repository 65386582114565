.section-contact {
  .panel-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 107px;
    padding: 0 79px;
    margin-bottom: 1000px;
    &.power-cut-ad {
      @include media-breakpoint-up(lg) {
        border-right: 1px solid #C4C4C4;
      }
    }

    .title {
      margin-top: 22px;
      color: #181818;
      font-size: 28px;
      font-weight: 600;
    }

    .phone {
      margin-top: 30px;
      color: #181818;
      font-size: 24px;
      font-weight: 600;
    }

    .description {
      margin-top: 25px;
      font-size: 17px;
      font-weight: 400;
    }

  }
}
