.agreements-accordion {
  .agreements-accordion-item {
    margin-bottom: 23px;
    @include box-shadow(0 0 10px rgba(0, 0, 0, 0.1));
    border-radius: 8px;

    .agreement-item-header {
      display: flex;
      align-items: center;
      height: 101px;
      background-color: #54b8f3;
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;

      .agreement-number {
        margin-left: 21px;
      }

      .agreement-alias {
        margin-left: 26px;
        font-weight: 700;

        input {
          display: inline-block;
          width: 200px;
          font-weight: 700;
          color: #ffffff;
          font-size: 18px;
          background-color: #54b8f3;
          border-color: #ffffff;
        }

        img {
          position: relative;
          top: -14px;
          right: -10px;
          cursor: pointer;
        }
      }

      .agreements-accordion-item-toggle {
        margin-left: auto;
      }
    }

    .agreement-item-body {
      padding: 0 0 35px;

      @import "section";
    }
  }
}
