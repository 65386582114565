&.page-profile {
  .profile-summary {
    label {
      color: #181818;
      font-size: 17px;
      font-weight: 400;
    }

    input {
      color: #54b8f3;
      font-size: 17px;
      font-weight: 400;
      border-radius: 8px;
      background-color: #ffffff;
      @include box-shadow(0 2px 3px rgba(0, 0, 0, 0.06), inset 0 2px 2px rgba(0, 0, 0, 0.22));

      &:read-only {
        color: #C4C4C4;
        font-size: 17px;
        font-weight: 400;
        box-shadow: none;
      }
    }

    .profile-non-editable-data {
      @include media-breakpoint-up(lg) {
        padding: 62px 67px 53px;
      }

      .form-group {
        margin-bottom: 0;
      }
    }

    .profile-editable-data {
      margin: 0 0 62px;
      padding: 0;

      input {
        flex: 0 0 auto;
        width: 248px;
      }

      .profile-phone, .profile-mobile {
        padding: 16px 0;
        background-color: #eef5fe;
      }

      @include media-breakpoint-up(lg) {
        .profile-phone, .profile-email {
          padding-left: 46px;
        }
      }

      button {
        margin: 40px 0 0 60px;
        width: 190px;
        border-radius: 8px;
        font-size: 17px;
        font-weight: 400;
      }
    }
  }
}
