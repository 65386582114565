section.agreements-panel {
  .dashboard-section-body {
    tr {
      td {
        &.action-buttons {
          width: 160px;

          .btn-open-agreement {
            width: 118px;
            height: 30px;
            border-radius: 8px;
            background-color: #54b8f3;
            color: #ffffff;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;

            img {
              margin-right: 7px;
            }
          }
        }

        &.delivery-type {
          img {
            width: 23px;
            height: 23px;
          }
        }
      }
    }
  }
}
