table {
  margin-bottom: 0;

  thead {
    height: 38px;
    tr {
      th {
        color: #181818;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;

        &:first-child {
          padding-left: 30px;
        }
        &:last-child {
          padding-right: 30px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 42px;

      &:nth-of-type(odd) {
        background-color: #eef5fe;
      }

      td {
        color: #181818;
        font-size: 17px;
        font-weight: 400;
        line-height: 30px;

        &:first-child {
          padding-left: 30px;
        }
        &:last-child {
          padding-right: 30px;
        }

        .btn-link {
          padding: 0;
          font-size: 17px;
          font-weight: 400;
          color: #54b8f3;
        }
      }
    }
  }

  tfoot {
    display: flex;
    justify-content: center;
  }
}

